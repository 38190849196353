import React, { useState } from "react";
import { FileContext } from "../context/file-context";

const FileProvider = ({ children }) => {
  const [getFiles, setFiles] = useState(null);
  return (
    <FileContext.Provider value={{ files: getFiles, updateFiles: setFiles }}>
      {children}
    </FileContext.Provider>
  );
};

export default FileProvider;

import React from "react";
import "./index.scss";
import LandingContainer from "../../../../containers/tools/uploader/LandingContainer";
import CopierContainer from "../../../../containers/tools/uploader/CopierContainer";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const Uploader = ({ handleFormSubmit, handleFormReset }) => {
  const { view, file, errorMessage } = useSelector((state) => state.uploader);
  return (
    <section className="PgUP-Uploader PgUP-block">
      {view.showError && (
        <p className="PgUP-Uploader__alert">
          <b>Error:</b> {errorMessage} Please try again later.
        </p>
      )}
      <LandingContainer />
      {!view.showComplete ? (
        <div className="PgUP-Uploader-info">
          <p className="PgUP-Uploader-info__item">
            <b>File: </b>
            {view.showSubmit || view.showLoader ? (
              file.name
            ) : (
              <>Drag and drop file or click to icon</>
            )}
          </p>
          <p className="PgUP-Uploader-info__item">
            (the file size must not exceed <b>50mb</b>)
          </p>
        </div>
      ) : (
        <p className="PgUP-Uploader__success">Uploading is complete!</p>
      )}
      {view.showSubmit && (
        <button
          className="PgUP-btn PgUP-Uploader__btn"
          onClick={handleFormSubmit}
        >
          Upload
        </button>
      )}
      {view.showComplete && (
        <>
          <CopierContainer />
          <button
            className="PgUP-btn PgUP-Uploader__btn PgUP-Uploader__btn_gray"
            onClick={handleFormReset}
          >
            Upload another file
          </button>
        </>
      )}
    </section>
  );
};

Uploader.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleFormReset: PropTypes.func.isRequired,
};

export default Uploader;

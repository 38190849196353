import React from "react";
import GIFLoader from "../../../../assets/images/gif/pages/tools/uploader/loader-small.gif";
import "./index.scss";
import { useSelector } from "react-redux";
import { getTrueClasses } from "../../../../helpers";
import PropTypes from "prop-types";

const Remover = ({ handleSubmitBtn, handleInpChange }) => {
  const { remover, view } = useSelector((state) => state.uploader);
  const { progress, complete, errors, url } = remover;
  return (
    <section className="PgUP-Remover PgUP-block">
      {(complete || errors.submit) && (
        <p
          className={getTrueClasses(
            "PgUP-Remover__message",
            errors.submit && "PgUP-Remover__message_error"
          )}
        >
          {errors.submit
            ? "An error occurred. Please try again later."
            : "Success!"}
        </p>
      )}
      {progress && (
        <img
          src={GIFLoader}
          alt="loader icon"
          className="PgUP-Remover__loader"
        />
      )}
      <div
        className={getTrueClasses(
          "PgUP-Remover-inp",
          (progress || view.showLoader) && "PgUP-Remover-inp_disabled"
        )}
      >
        <label className="PgUP-Remover-inp__label">File URL:</label>
        <input
          placeholder="https://assets.ctfassets.net/0yu6ph4occja/3S6JnTMN3ruHXZJakyKrB5/4351653f294ec9cba861ec2303b4ea7f/10-hot-niches-dec-2018.pdf"
          className={getTrueClasses(
            "PgUP-input PgUP-Remover-inp__input",
            errors.url && "PgUP-Remover-inp__input_error"
          )}
          type="text"
          value={url}
          onChange={handleInpChange}
        />
        <button
          onClick={handleSubmitBtn}
          className="PgUP-btn PgUP-Remover-inp__btn"
        >
          Remove
        </button>
      </div>
      <p className="PgUP-Remover__info">
        Removing may take between 24 to 48 hours until assets are made
        unavailable from the delivery network.
      </p>
    </section>
  );
};

Remover.propTypes = {
  handleSubmitBtn: PropTypes.func.isRequired,
  handleInpChange: PropTypes.func.isRequired,
};

export default Remover;

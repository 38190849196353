import React, { useContext } from "react";
import Uploader from "../../../components/tools/uploader/Uploader";
import { FileContext } from "../../../store/context/file-context";
import { useDispatch } from "react-redux";
import { uploaderActions } from "../../../store/slice/uploader-slice";
import UploaderController from "../../../controllers/tools/uploaderController";

const fileTypes = [
  "application/pdf",
  ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

const UploaderContainer = () => {
  const { files, updateFiles } = useContext(FileContext);
  const dispatch = useDispatch();
  const handleStoreReset = () => {
    dispatch(uploaderActions.resetAllStates());
  };
  const handleUploadSuccess = (url) => {
    dispatch(uploaderActions.updateViewState("showComplete"));
    dispatch(uploaderActions.setFileUrl(url));
  };
  const handleUploadError = () => {
    dispatch(uploaderActions.updateViewState("showForm"));
    dispatch(uploaderActions.createCommonError());
  };
  const handleUploadSubmit = () => {
    if (!files || !fileTypes.includes(files[0].type)) {
      updateFiles(null);
      dispatch(
        uploaderActions.setErrorMessage(
          "The file type is not supported or is empty."
        )
      );
      dispatch(uploaderActions.updateViewState("showError"));
    } else {
      dispatch(uploaderActions.resetError());
      dispatch(uploaderActions.updateViewState("showLoader"));
      new UploaderController(
        files,
        () => handleUploadError(),
        (url) => handleUploadSuccess(url)
      ).uploadProcessInitiating();
    }
  };
  return (
    <Uploader
      handleFormSubmit={handleUploadSubmit}
      handleFormReset={handleStoreReset}
    />
  );
};

export default UploaderContainer;

import React from "react";
import PropTypes from "prop-types";
import SvgDarkLogo from "../../../assets/images/vector/components/common/logo_amzscout_black.svg";
import SvgCnLogo from "../../../assets/images/vector/Layout/Header/logos/logo_amzscout_cn.svg";
import { getTrueClasses } from "../../../helpers";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const MinimalHeader = ({ isCnPricing, path }) => (
  <header className="MinimalHeader">
    <div className="container">
      <div
        className={getTrueClasses(
          "MinimalHeader-row",
          !!isCnPricing && "MinimalHeader-row__cn"
        )}
      >
        <TrackedLink
          path={path}
          class="MinimalHeader__link"
          action="logo"
          category="PricingLP"
        >
          <img src={isCnPricing ? SvgCnLogo : SvgDarkLogo} alt="logo" />
        </TrackedLink>
      </div>
    </div>
  </header>
);
MinimalHeader.propTypes = {
  isCnPricing: PropTypes.bool,
};
export default MinimalHeader;

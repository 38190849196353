import React from "react";
import "../../assets/scss/pages/tools/uploader/index.scss";
import LayoutEmpty from "../../layouts/LayoutEmpty";
import MinimalHeader from "../../components/Layout/MinimalHeader";
import Seo from "../../components/Seo";
import Container from "../../components/common/Container";
import UploaderContainer from "../../containers/tools/uploader/UploaderContainer";
import ReduxProvider from "../../store/providers/ReduxProvider";
import FileProvider from "../../store/providers/FileProvider";
import RemoverContainer from "../../containers/tools/uploader/RemoverContainer";

const UploaderPage = () => {
  return (
    <ReduxProvider>
      <FileProvider>
        <LayoutEmpty
          disableCookie
          disableTagManager
          disableHelpBlock
          className="PgUP-layout"
        >
          <Seo title="AmzScout: Report Uploader" />
          <MinimalHeader path="/" />
          <Container>
            <UploaderContainer />
            <RemoverContainer />
          </Container>
        </LayoutEmpty>
      </FileProvider>
    </ReduxProvider>
  );
};

export default UploaderPage;

class UserModel {
  constructor() {
    this.requestHeaders = {
      "Content-type": "application/octet-stream",
      Authorization: `Bearer ${process.env.CONTENTFUL_MANAGEMENT_TOKEN}`,
    };
  }

  request(data) {
    return fetch(
      `https://upload.contentful.com/spaces/${process.env.CONTENTFUL_SPACE_ID}/uploads`,
      {
        headers: this.requestHeaders,
        method: "POST",
        body: data,
      }
    );
  }
}

export default UserModel;

import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { getTrueClasses } from "../../../../helpers";
import GIFLoader from "../../../../assets/images/gif/pages/tools/uploader/loader.gif";
import SVGDoneIcon from "../../../../assets/images/vector/pages/tools/uploader/done.svg";
import PropTypes from "prop-types";

const Landing = ({ handleInpChange }) => {
  const { view } = useSelector((state) => state.uploader);
  const displayLand = view.showForm || view.showSubmit || view.showError;
  return (
    <div className="PgUP-Landing">
      <div
        className={getTrueClasses(
          "PgUP-Landing-uploader",
          (view.showSubmit || view.showLoader) &&
            "PgUP-Landing-uploader_submit",
          view.showComplete && "PgUP-Landing-uploader_complete"
        )}
      >
        {displayLand ? (
          <>
            <input
              onChange={handleInpChange}
              type="file"
              accept="application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="PgUP-Landing-uploader__inp"
            />
            <button className="PgUP-Landing-uploader__btn" />
          </>
        ) : (
          <img
            className="PgUP-Landing-uploader__loader"
            src={view.showLoader ? GIFLoader : SVGDoneIcon}
            alt="Loader Gif"
          />
        )}
      </div>
    </div>
  );
};

Landing.propTyped = {
  handleInpChange: PropTypes.func.isRequired,
};

export default Landing;

import UploaderModel from "../../models/tools/uploader/UploaderModel";
import "regenerator-runtime/runtime";
import { createClient } from "contentful-management";
import { log } from "../../helpers";

class UploaderController extends UploaderModel {
  #files;
  #handleFail;
  #handleSuccess;
  #logTitle;

  constructor(files, handleFail, handleSuccess) {
    super();
    this.#files = files;
    this.#handleFail = handleFail;
    this.#handleSuccess = handleSuccess;
    this.#logTitle = "Uploader:";
  }

  static removeAsset = (assetId) => {
    const client = createClient({
      accessToken: process.env.CONTENTFUL_MANAGEMENT_TOKEN,
    });
    return client
      .getSpace(process.env.CONTENTFUL_SPACE_ID)
      .then((space) => space.getEnvironment("master"))
      .then((environment) => {
        return environment
          .getAsset(assetId)
          .then((asset) => {
            if (asset.isPublished()) {
              return asset.unpublish();
            } else {
              return asset;
            }
          })
          .then((asset) => {
            return asset.delete();
            // if (!asset.isArchived()) {
            //   return asset.archive();
            // }
          });
      });
  };

  #prepareBodyForRequest = (file, id) => {
    this.updatedFileData = {
      fields: {
        title: {
          "en-US": file.name,
        },
        file: {
          "en-US": {
            fileName: file.name,
            contentType: file.type,
            uploadFrom: {
              sys: {
                type: "Link",
                linkType: "Upload",
                id: id,
              },
            },
          },
        },
      },
    };
  };

  #publishFile = () => {
    const client = createClient({
      accessToken: process.env.CONTENTFUL_MANAGEMENT_TOKEN,
    });
    client
      .getSpace(process.env.CONTENTFUL_SPACE_ID)
      .then((space) => space.getEnvironment("master"))
      .then((environment) => {
        environment
          .createAsset(this.updatedFileData)
          .then((asset) => {
            log("processing...", this.#logTitle);
            return asset.processForLocale("en-US", {
              processingCheckWait: 2000,
            });
          })
          .then((asset) => {
            log("publishing...", this.#logTitle);
            return asset.publish();
          })
          .then((asset) => {
            return `https:${asset.fields.file["en-US"].url}`;
          })
          .then((url) => {
            this.#handleSuccess(url);
          });
      })
      .catch(() => {
        this.#handleFail();
      });
  };

  static copyUrl = (url) => {
    if (url) {
      return navigator.clipboard.writeText(url);
    } else {
      return Promise.reject(new Error("Url is empty"));
    }
  };

  uploadProcessInitiating = () => {
    this.request(this.#files[0])
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((node) => {
        this.#prepareBodyForRequest(this.#files[0], node.sys.id);
        this.#publishFile();
      })
      .catch((e) => {
        log(e, "Upload File Error:");
        this.#handleFail();
      });
  };
}

export default UploaderController;

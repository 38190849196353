import React from "react";
import Copier from "../../../components/tools/uploader/Copier";
import { useDispatch, useSelector } from "react-redux";
import { uploaderActions } from "../../../store/slice/uploader-slice";
import UploaderController from "../../../controllers/tools/uploaderController";
import { log } from "../../../helpers";

const CopierContainer = () => {
  const { file } = useSelector((state) => state.uploader);
  const dispatch = useDispatch();
  const handleCopy = () => {
    UploaderController.copyUrl(file.url)
      .then(() => {
        dispatch(uploaderActions.updateCopyStatus());
      })
      .catch((err) => {
        dispatch(uploaderActions.updateCopyError());
        log(err, "Copy Url:");
      });
  };
  return <Copier handleCopyClick={handleCopy} />;
};

export default CopierContainer;

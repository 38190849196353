import React from "react";
import Remover from "../../../components/tools/uploader/Remover";
import { useDispatch, useSelector } from "react-redux";
import { uploaderActions } from "../../../store/slice/uploader-slice";
import UploaderController from "../../../controllers/tools/uploaderController";
import { log } from "../../../helpers";

const RemoverContainer = () => {
  const dispatch = useDispatch();
  const { remover } = useSelector((state) => state.uploader);
  const { url } = remover;

  const cleanFormWithError = () => {
    dispatch(uploaderActions.throwRemoverError("url"));
    dispatch(uploaderActions.updateRemoverUrl(""));
  };

  const handleSubmit = () => {
    if (url.length > 0 && url.includes("ctfassets.net")) {
      const urlSplit = url.split("/");
      if (
        urlSplit[4].length > 0 &&
        urlSplit[3] === process.env.CONTENTFUL_SPACE_ID
      ) {
        log(urlSplit[4], "Remover Asset is:");
        dispatch(uploaderActions.showProgressState());
        UploaderController.removeAsset(urlSplit[4])
          .then(() => {
            dispatch(uploaderActions.showCompleteState());
            dispatch(uploaderActions.updateRemoverUrl(""));
          })
          .catch((err) => {
            log(err, "Remover Error is:");
            dispatch(uploaderActions.throwRemoverError("submit"));
          });
      } else {
        cleanFormWithError();
      }
    } else {
      cleanFormWithError();
    }
  };

  const handleFormChange = (event) => {
    dispatch(uploaderActions.updateRemoverUrl(event.target.value));
  };

  return (
    <Remover
      handleSubmitBtn={handleSubmit}
      handleInpChange={handleFormChange}
    />
  );
};

export default RemoverContainer;

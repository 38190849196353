import React, { useContext } from "react";
import Landing from "../../../components/tools/uploader/Landing";
import { useDispatch } from "react-redux";
import { uploaderActions } from "../../../store/slice/uploader-slice";
import { FileContext } from "../../../store/context/file-context";

const LandingContainer = () => {
  const { updateFiles } = useContext(FileContext);
  const dispatch = useDispatch();
  const handleChangeEvent = (e) => {
    if (e.target.files.length > 0) {
      updateFiles(e.target.files);
      dispatch(uploaderActions.resetError());
      dispatch(uploaderActions.setFileName(e.target.files[0].name));
      dispatch(uploaderActions.updateViewState("showSubmit"));
    }
  };
  return <Landing handleInpChange={handleChangeEvent} />;
};

export default LandingContainer;

import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const Copier = ({ handleCopyClick }) => {
  const { file } = useSelector((state) => state.uploader);
  return (
    <div className="PgUP-Copier">
      <div className="PgUP-Copier-link">
        <label className="PgUP-Copier-link__label">Document Link:</label>
        <input
          className="PgUP-input PgUP-Copier-link__input"
          readOnly="readonly"
          type="text"
          value={file.url}
        />
        <button className="PgUP-Copier-link__button" onClick={handleCopyClick}>
          Copy
        </button>
      </div>
      {file.copyStatus && (
        <p className="PgUP-Copier__info">Link was copied to clipboard</p>
      )}
      {file.copyError && (
        <p className="PgUP-Copier__info PgUP-Copier__info_alert">
          Something went wrong. Please try again.
        </p>
      )}
    </div>
  );
};

Copier.propTypes = {
  handleCopyClick: PropTypes.func.isRequired,
};

export default Copier;
